import React from "react";
import styled from "styled-components";

const YouTubeThumbnail = ({ videoId }) => {
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`; // 고화질 썸네일

  return (
    <Wrapper>
      <StyledLink
        href={`https://www.youtube.com/watch?v=${videoId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Thumbnail
          src={thumbnailUrl}
          alt={`YouTube Thumbnail for ${videoId}`}
        />
      </StyledLink>
    </Wrapper>
  );
};

export default YouTubeThumbnail;

// Styled Components
const Wrapper = styled.div`
  text-align: center;
`;

const StyledLink = styled.a`
  display: inline-block;
  text-decoration: none;
`;

const Thumbnail = styled.img`
  max-width: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
`;
