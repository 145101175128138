import React from "react";
import styled from "styled-components";
import InstagramThumbnail from "../components/InstagramThumbnail"; // 기존 컴포넌트 임포트
import YouTubeThumbnail from "../components/YouTubeThumbnail"; // 기존 컴포넌트 임포트
import youtube from "../assets/images/youtube.png";

const MyTeam = () => {
  // Instagram 게시물 링크 배열
  const postLinks = [
    "DCykvM3MD7b",
    "DCqM0M_KRoI",
    "DCqK6F7sh8O",
    "DCjEhazzzKn",
    "DCgcbyMy7ex",
  ];
  const videoIds = [
    "ZN4BgFRCr6I", // 첫 번째 동영상 ID
    "cEk3g400AAE",
    "JTrOcy4Fx7Y",
    "WEhUFKv_CXE",
    "cEk3g400AAE",
  ];
  return (
    <Wrapper>
      <div>
        <Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
              width: 140,
            }}
          >
            <img src={youtube} alt="youtubeIco" height={50}></img>
            t1lol
          </div>
          {videoIds.map((videoId, index) => (
            <YouTubeThumbnail key={index} videoId={videoId} />
          ))}
        </Grid>
      </div>
      <div>
        <Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <img
              height={50}
              src={"https://asset.lckfantasy.com/logo/insta.webp"}
              alt={`instaIco`}
            />
            t1lol
          </div>
          {postLinks.map((postShortcode, index) => (
            <InstagramThumbnail key={index} postShortcode={postShortcode} />
          ))}
        </Grid>
      </div>
      <div>
        <Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <img
              height={50}
              src={"https://asset.lckfantasy.com/logo/insta.webp"}
              alt={`instaIco`}
            />
            t1lol
          </div>
          {postLinks.map((postShortcode, index) => (
            <InstagramThumbnail key={index} postShortcode={postShortcode} />
          ))}
        </Grid>
      </div>
    </Wrapper>
  );
};

export default MyTeam;

// Styled Components
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: white;
  font-family: "Dohyeon";
`;

const Title = styled.h1`
  font-size: 32px;
  color: #4a3d8f;
  margin-bottom: 30px;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* 썸네일 간격 */
  justify-content: center;
  width: 100%;
`;
