import React from "react";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
`;

const PlayerPhoto = () => {
  return (
    <Card>
      <CardContent>
        <Image
          src="https://asset.lckfantasy.com/team/t1/faker.webp"
          alt="Player Photo"
          width={160}
        />
      </CardContent>
    </Card>
  );
};

export default PlayerPhoto;
