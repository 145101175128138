import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { instanceAxios } from "../api/axios";
import {
  InputGroup,
  InputPasswordValidateGroup,
  InputValidateGroup,
} from "../components/inputs/InputGroups";

import AlertModal from "../components/modals/AlertModal";
import TeamList from "../atom/TeamList";
import { useRecoilState } from "recoil";
import { AlertMessage, IsAlertOpen } from "../atom/Atoms";
const SignUp = () => {
  const navigate = useNavigate();
  const [isOpenTimer, setIsOpenTimer] = useState(false);
  const [isTimer, setIsTimer] = useState(false);
  const [timerId, setTimerId] = useState(null); // 타이머 ID
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState("");
  const [emailVaildation, setEmailVaildation] = useState(false);
  const [tokenVaildation, setTokenVaildation] = useState(false);
  const [passwordVaildation, setPasswordVaildation] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [currentTeam, setCurrentTeam] = useState("");

  // Alert Modal
  const [isAlertOpen, setIsAlertOpen] = useRecoilState(IsAlertOpen);
  const [alertMessage, setAlertMessage] = useRecoilState(AlertMessage);

  useEffect(() => {
    let intervalId;

    const startTimer = () => {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(intervalId);
            setIsAlertOpen(true);
            setAlertMessage("인증번호 입력시간이 초과하였습니다❌");
            window.location.reload();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      setTimerId(intervalId);
    };

    if (isTimer) {
      startTimer();
    } else {
      clearInterval(intervalId); // 타이머 멈춤
    }

    return () => {
      clearInterval(intervalId); // 컴포넌트 언마운트 시 타이머 멈춤
    };
  }, [isTimer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    //setTimer();
    return (
      <span>
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </span>
    );
  };
  const keyDownEmail = (e) => {
    console.log(e.key, "이메일 키");
  };
  const keyDownToken = (e) => {
    console.log(e.key, "이메일 토큰");
  };
  // 이메일 보내기
  const checkEmail = async (e) => {
    e.preventDefault();
    let naverEmail = email + "@naver.com";
    try {
      const response = await instanceAxios.post("/auth/sendToken", {
        naverEmail,
      });

      if (response.status === 200) {
        setIsAlertOpen(true);
        setAlertMessage("메일로 보낸 인증번호를 확인하세요✔");
        setIsTimer(true);
        setIsOpenTimer(true);
        setTokenVaildation(false);
      }

      console.log(response);
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors(err.response.data);
      }

      console.error(err);
    }
  };
  // 토큰 검증
  const checkToken = async (e) => {
    e.preventDefault();
    try {
      const response = await instanceAxios.post("/auth/checkToken", {
        email,
        token,
      });

      if (response.status === 200) {
        setIsTimer(false);
        setIsOpenTimer(false);
        setTokenVaildation(true);
        setIsAlertOpen(true);
        setAlertMessage("메일 인증에 성공하셨습니다🎉");
        console.log("검증 성공🎉");
      }

      console.log(response);
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors(err.response.data);
      }

      console.error(err);
    }
  };
  // 이메일 직접 쓰기
  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    e.preventDefault();
    // ***.com 정규식
    let re = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
    let result = re.test(e.target.value);
    console.log(result, "페스워드 테스트");
    setPasswordVaildation(result);
    setPassword(e.target.value);
  };
  const handleConPassword = (e) => {
    e.preventDefault();
    setConPassword(e.target.value);
  };

  // 토큰 쓰기
  const handleToken = (e) => {
    e.preventDefault();
    setToken(e.target.value);
  };
  const submitSignUp = async (e) => {
    e.preventDefault();

    try {
      const response = await instanceAxios.post("/user/signup", {
        username,
        password,
        email,
        token,
        currentTeam,
      });

      if (response.status === 200) {
        setIsTimer(false);
        setIsOpenTimer(false);
        setTokenVaildation(true);
        setIsAlertOpen(true);
        setAlertMessage("LCK Fantasy 가입을 축하드립니다🎉");
        navigate("/login");

        console.log("가입 성공🎉");
      }

      console.log(response);
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors(err.response.data);
      }

      console.error(err);
    }
  };

  return (
    <Wrapper>
      <Box>
        <WrapTitle>
          <Title>Sign UP!!</Title>
          Enjoy LCK by LCK Fantasy
        </WrapTitle>
        <InputWrapper>
          <SubTitle>Email</SubTitle>
          <EmailWrapper>
            <div
              style={{
                flexDirection: "row",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                placeholder="네이버 계정만 가능합니다"
                value={email}
                onChange={handleEmail}
                error={errors.email}
              />
              <div style={{ width: "30%", paddingLeft: 5, fontSize: 20 }}>
                @naver.com
              </div>
            </div>
            {emailVaildation ? (
              <Button active onClick={checkEmail}>
                Email 인증하기
              </Button>
            ) : (
              <Button inactive>Email 인증하기</Button>
            )}
          </EmailWrapper>
        </InputWrapper>
        <WrapWriteToken>
          <TokenMsg>
            이메일로 전송된 인증번호를 입력해주세요. &nbsp;&nbsp;
            {isOpenTimer && formatTime(remainingTime)}
            &nbsp;&nbsp;
            {!tokenVaildation ? (
              <button
                style={{
                  cursor: "pointer",
                  borderRadius: 32,
                  width: 80,
                  fontFamily: "Dohyeon",
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = "white")}
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = "transparent")
                }
                onClick={checkToken}
              >
                인증하기
              </button>
            ) : (
              <button
                style={{
                  cursor: "not-allowed", // 커서를 비활성화된 상태로 설정
                  pointerEvents: "none",
                  borderRadius: 32,
                  width: 80,
                  fontFamily: "Dohyeon",
                }}
              >
                인증완료
              </button>
            )}
          </TokenMsg>
          {tokenVaildation ? (
            <InputValidateGroup
              placeholder="인증번호를 입력해주세요."
              readonly
              value={token}
              setValue={handleToken}
              error={errors.token}
            />
          ) : (
            <InputValidateGroup
              placeholder="인증번호를 입력해주세요."
              value={token}
              setValue={handleToken}
              keyDown={keyDownToken}
              error={errors.token}
            />
          )}
        </WrapWriteToken>
        <InputWrapper style={{ paddingTop: 10 }}>
          <SubTitle>Username</SubTitle>
          <EmailWrapper>
            <InputGroup
              placeholder="닉네임을 입력해주세요"
              value={username}
              setValue={setUsername}
              error={errors.userName}
            />
          </EmailWrapper>
        </InputWrapper>
        <InputWrapper style={{ paddingTop: 20 }}>
          <SubTitle>Password</SubTitle>{" "}
          <EmailWrapper>
            <InputPasswordValidateGroup
              placeholder="비밀번호를 입력해주세요"
              value={password}
              setValue={handlePassword}
              error={errors.password}
            />
            {password.length === 0 || passwordVaildation ? (
              <SubTitle style={{ width: "100%", fontSize: 16 }}>
                &nbsp;&nbsp; 8~16자 문자, 숫자, 특수문자를 사용하세요
              </SubTitle>
            ) : (
              <SubTitle style={{ width: "100%", fontSize: 16, color: "red" }}>
                &nbsp;&nbsp; 8~16자 문자, 숫자, 특수문자를 사용하세요
              </SubTitle>
            )}
            <InputPasswordValidateGroup
              placeholder="비밀번호를 확인해주세요"
              value={conPassword}
              setValue={handleConPassword}
              error={errors.conPassword}
            />
            {password !== conPassword && conPassword.length > 0 && (
              <SubTitle style={{ width: "100%", fontSize: 16, color: "red" }}>
                &nbsp;&nbsp; 같은 비밀번호를 입력해주세요
              </SubTitle>
            )}
          </EmailWrapper>
        </InputWrapper>
        <SubTitle
          style={{
            width: 280,
            height: 50,
            paddingTop: 10,
            alignItems: "center",
          }}
        >
          Pick Your Team!!{" "}
        </SubTitle>
        <TeamWrapper>
          {TeamList.map(({ name, path }) => {
            return (
              <Team
                onClick={() => setCurrentTeam(name)}
                key={name}
                style={{ cursor: "pointer" }}
              >
                <img height={32} src={`${path}`} alt={`${name}`} />
              </Team>
            );
          })}
        </TeamWrapper>
        <SubTitle
          style={{
            width: 280,
            height: 50,
            paddingTop: 10,
            alignItems: "center",
          }}
        >
          MY Team :
          {currentTeam ? (
            <img
              width={32}
              height={32}
              src={`https://assets.lckfantasy.com/logo/${currentTeam.toLowerCase()}.svg`}
              alt={`${currentTeam}`}
              style={{ paddingLeft: 5, objectFit: "contain" }}
            />
          ) : null}{" "}
          {currentTeam.toLocaleUpperCase()}
        </SubTitle>
        {username &&
        emailVaildation &&
        passwordVaildation &&
        tokenVaildation &&
        currentTeam &&
        password === conPassword ? (
          <Button onClick={(e) => submitSignUp(e)}>회원가입</Button>
        ) : (
          <Button inactive>회원가입</Button>
        )}
        <LoginWrapper>
          <SubTitle
            style={{
              width: 230,
              height: 50,
              paddingTop: 10,
              alignItems: "center",
            }}
          >
            이미 가입하셨나요?
          </SubTitle>
          <LoginButton
            style={{
              width: 150,
              alignItems: "center",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
                marginTop: "8px",
              }}
              to={"/login"}
            >
              Sign In
            </Link>
          </LoginButton>
        </LoginWrapper>
      </Box>
      {/* alert */}
      {isAlertOpen && <AlertModal></AlertModal>}
      {/* alert */}
    </Wrapper>
  );
};

export default SignUp;
const Box = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 60px;
`;
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
  font-family: "Dohyeon";
`;
const InputWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
`;
const EmailWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background: white;
`;
const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;
const WrapWriteToken = styled.div`
  background: #f0f0f0;
  padding: 16px;
  margin-top: 12px;
`;
const TokenMsg = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;
const WrapReSendLink = styled.div`
  display: flex;
  align-items: center;
`;
const ResendBtn = styled.button`
  font-size: 14px;
  color: #434343;
`;
const Button = styled.button`
  display: block;
  border: none;
  width: 100%;
  margin: 10px;
  cursor: pointer;
  border-radius: 32px;
  font-size: 16px;
  background: ${(props) => (props.inactive ? `#BFBFBF` : "white")};
  color: ${(props) => (props.inactive ? `black` : "#4f3a6c")};
  border: 1px solid ${(props) => (props.inactive ? `grey` : "#4f3a6c")};
  cursor: ${(props) => (props.inactive ? `default` : null)};
  &:hover {
    background: ${(props) => (props.inactive ? null : "#4f3a6c")};
    color: ${(props) => (props.inactive ? null : "white")};
  }
`;
const LoginButton = styled.button`
  display: block;
  border: none;
  width: 120px;
  cursor: pointer;
  border-radius: 32px;
  font-size: 16px;
`;
const WrapTitle = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  font-family: "Dohyeon";
  line-height: 30px;
  &::after {
    display: block;
    margin-bottom: 18px;

    content: "";
    left: 0;
    width: 100%;
    height: 1px;
    background: #8c8c8c;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: black;
  word-break: break-all;
`;
const SubTitle = styled.div`
  font-size: 25px;
  width: 100px;
  color: black;
  padding-right: 10px;
`;
const Team = styled.div`
  display: flex;
  flex: auto;
  padding: 5px;
  width: 60px;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const TeamWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 400px;
  align-items: center;
`;
const Input = styled.input`
  padding: 6px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid grey;
  touch-action: manipulation;
  ime-mode: disabled;

  &:focus {
    border: 1px solid #4f3a6c;
  }

  &::placeholder {
    color: #afafaf; // 문자열은 큰따옴표가 아니라, 그냥 컬러 값으로 수정
  }
`;
