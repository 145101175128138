import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
const Footer = () => {
  const isDesktop = useMediaQuery({ minWidth: 1120 });

  return (
    <Wapper>
      {isDesktop ? (
        <>
          <FooterLink href="mailto:lckfantasy.chang@gmail.com">
            문의하기&기술도움: lckfantasy.chang@gmail.com
          </FooterLink>
          <p>Copyright &copy; lckfantasy.chang &nbsp; All rights reserved.</p>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: "80%",
              display: "flex",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <FooterMobileLink href="mailto:lckfantasy.chang@gmail.com">
              문의하기&기술도움:
              <br /> lckfantasy.chang@gmail.com
            </FooterMobileLink>
          </div>
          <div style={{ width: "80%", textAlign: "end", marginRight: 10 }}>
            <p>
              &copy; lckfantasy.chang <br /> All rights reserved.
            </p>
          </div>
        </div>
      )}
    </Wapper>
  );
};

export default Footer;
const Wapper = styled.div`
  width: 100%;
  height: 100px;
  justify-content: center;
  color: white;
  align-items: center;
  display: flex;
  background-color: #4f3a6c;
`;

const FooterText = styled.p`
  font-size: 14px;
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  padding-right: 20px;
  &:hover {
    text-decoration: underline;
  }
`;
const FooterMobileLink = styled.a`
  color: white;
  text-decoration: none;
`;
