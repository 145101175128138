import React from "react";
import TeamList from "../atom/TeamList";
import styled from "styled-components";
const Header = () => {
  const openInsta = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Wapper>
      <Insta>
        <Instaletter>TEAM</Instaletter>
        <img
          height={18}
          src={"https://asset.lckfantasy.com/logo/insta.webp"}
          alt={`instaIco`}
        />
      </Insta>
      {TeamList.map(({ name, path, href }) => {
        return (
          <Linker onClick={() => openInsta(href)} key={name}>
            <img height={32} src={`${path}`} alt={`${name}`} />
          </Linker>
        );
      })}
    </Wapper>
  );
};

export default Header;
const Wapper = styled.div`
  width: 90%;
  float: bottom;
  justify-content: center;
  display: flex;
`;

const Insta = styled.div`
  font-size: 10pt;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "lckEN";
`;
const Instaletter = styled.div`
  font-size: 10pt;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: "lckEN";
`;
const Linker = styled.a`
  margin-left: 35px;
  padding-top: 15px;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
