const LaneList = [
  {
    lane: "TOP",
    path: "https://asset.lckfantasy.com/lane/Top_icon.webp",
  },
  {
    lane: "JUG",
    path: "https://asset.lckfantasy.com/lane/Jungle_icon.webp",
  },
  {
    lane: "MID",
    path: "https://asset.lckfantasy.com/lane/Middle_icon.webp",
  },
  {
    lane: "ADC",
    path: "https://asset.lckfantasy.com/lane/Bottom_icon.webp",
  },
  {
    lane: "SUP",
    path: "https://asset.lckfantasy.com/lane/Support_icon.webp",
  },
];

export default LaneList;
