import React, { useEffect, useState } from "react";
import styled from "styled-components";
import youtube from "../assets/images/youtube.png";
import afreecatv from "../assets/images/afreeca.png";
import naver from "../assets/images/naver.png";
import { instanceAxios } from "../api/axios";
import { useMediaQuery } from "react-responsive";
import PlayerPhoto from "../components/PlayerPhoto";
import DescriptionBox from "../components/DescriptionBox";
const Home = () => {
  const isDesktop = useMediaQuery({ minWidth: 1120 });
  const [count, setCount] = useState();
  const [gameDay, setGameDay] = useState(true);
  const [schedule, setSchedule] = useState([]);
  const special = process.env.REACT_APP_SPECIAL_SALT;
  const lcklogo = "https://asset.lckfantasy.com/logo/lcklogo2025.webp";

  // 경기 없는 날 렌더링 함수 weekly best
  return (
    <Wrapper>
      {isDesktop ? (
        <>
          <img
            width={520}
            height={241}
            style={{ paddingLeft: 25 }}
            src={lcklogo}
            alt="lcklogo"
          />
          <Column>
            <Row>
              <PlayerPhoto></PlayerPhoto>
              <DescriptionBox></DescriptionBox>
            </Row>
            <Row>
              <PlayerPhoto></PlayerPhoto>
              <DescriptionBox></DescriptionBox>
            </Row>
          </Column>
        </>
      ) : (
        <>
          <img width={260} height={120} src={lcklogo} alt="lcklogo" />
          <Column>
            <PlayerPhoto></PlayerPhoto>
            <DescriptionBox></DescriptionBox>

            <PlayerPhoto></PlayerPhoto>
            <DescriptionBox></DescriptionBox>
          </Column>
        </>
      )}
    </Wrapper>
  );
};

export default Home;
const Wrapper = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "lckEN";
  flex-direction: column;
  font-family: "Dohyeon";
  background: linear-gradient(to bottom right, #e8e6f5, #cbd0ee);
`;
const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 40px;
  cursor: pointer;
  text-decoration: none;
  //color: #4f3a6c;
  font-family: "lckEN";
`;
const Match = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Linker = styled.a`
  margin-left: 35px;
  padding-top: 15px;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;
const LckTitle = styled.div`
  font-family: "Arial", sans-serif; /* You can replace with a more specific or custom font */
  font-weight: bold;
  font-size: 3rem; /* Adjust based on your preference */
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #2c2c54; /* Dark color similar to the image */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 20px 0;

  &::before,
  &::after {
    content: "";
    width: 50px;
    height: 2px;
    background-color: #2c2c54; /* Dark color similar to the image */
    position: absolute;
    top: 50%;
  }

  &::before {
    left: -60px;
  }

  &::after {
    right: -60px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Column = styled.div`
  width: 100%;
  padding: 10px;

  @media (min-width: 768px) {
    width: 50%;
  }
`;
