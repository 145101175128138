import React from "react";
import styled from "styled-components";

function NotFound() {
  return (
    <NotFoundWrapper>
      <Title>404</Title>
      <Subtitle>페이지를 찾을 수 없습니다.</Subtitle>
      <Button href="/">홈으로 돌아가기</Button>
    </NotFoundWrapper>
  );
}

export default NotFound;

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  background: linear-gradient(to bottom, #eae8f9, #d7d5f0);
  color: #2f2e41;
  text-align: center;
  font-family: "Dohyeon";
`;

const Title = styled.h1`
  font-size: 6rem;
  font-weight: 800;
  color: #3d3a78;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #5c5b85;
`;

const Button = styled.a`
  margin-top: 2rem;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #3d3a78;
  color: white;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #56528e;
    cursor: pointer;
  }
`;
