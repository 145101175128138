import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { listPageReLoading, focusNav } from "../atom/Atoms";
import navList from "../atom/NavList";
import styled from "styled-components";
import Logo from "../assets/images/lcklogo.png";
import WhiteLogo from "../assets/images/lcklogowhite.png";
import { useMediaQuery } from "react-responsive";
import { hover } from "@testing-library/user-event/dist/hover";
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1120 });
  return isDesktop ? children : null;
};
function Nav() {
  const [focusPath, setFocusPath] = useRecoilState(focusNav);

  const location = useLocation();
  const focus = (location.pathname.split("/")[2] || "").toLocaleLowerCase();
  const bottomFocus = location.pathname.split("/")[3];
  const navFantasyRender = () => {
    return (
      <Navbottom>
        <NavbottomNull></NavbottomNull>
        <Link
          style={{ textDecoration: "none", color: "#a6a6b9", marginTop: "8px" }}
          to={"/page/Fantasy/"}
        >
          {!(bottomFocus === "draft" || bottomFocus === "ranking") ? (
            <NavbottomFocusContent>fantasy</NavbottomFocusContent>
          ) : (
            <NavbottomContent>fantasy</NavbottomContent>
          )}
        </Link>
        <Link
          style={{ textDecoration: "none", color: "#a6a6b9", marginTop: "8px" }}
          to={"/page/Fantasy/draft"}
        >
          {bottomFocus === "draft" ? (
            <NavbottomFocusContent>draft</NavbottomFocusContent>
          ) : (
            <NavbottomContent>draft</NavbottomContent>
          )}
        </Link>
        <Link
          style={{ textDecoration: "none", color: "#a6a6b9", marginTop: "8px" }}
          to={"/page/Fantasy/ranking"}
        >
          {bottomFocus === "ranking" ? (
            <NavbottomFocusContent>ranking</NavbottomFocusContent>
          ) : (
            <NavbottomContent>ranking</NavbottomContent>
          )}
        </Link>
      </Navbottom>
    );
  };
  const navVideoRender = () => {
    return (
      <Navbottom>
        <NavbottomNull></NavbottomNull>
        <NavbottomContent>LCK Video</NavbottomContent>
        <NavbottomContent>MY TEAM Video</NavbottomContent>
      </Navbottom>
    );
  };
  const navUserVideoRender = () => {
    return (
      <Navbottom>
        <NavbottomNull></NavbottomNull>
        <NavbottomContent>MY TEAM Video</NavbottomContent>
        <NavbottomContent>LCK Video</NavbottomContent>
      </Navbottom>
    );
  };

  return (
    <>
      <Flex>
        <NavWrapper>
          <NavWrapper>
            <LeftSide>
              <HeaderImage>
                <Link to="/" onClick={() => setFocusPath("")}>
                  <img src={Logo} height={94} />
                </Link>
              </HeaderImage>
              {navList.map(({ title, path }) => {
                return (
                  <Link
                    key={path}
                    to={`/page/${path}`}
                    style={{ textDecoration: "none" }}
                  >
                    {focus === path.toLocaleLowerCase() ? (
                      <LeftSideSelectLink key={path}>
                        {title}
                      </LeftSideSelectLink>
                    ) : (
                      <LeftSideLink key={path}>{title}</LeftSideLink>
                    )}
                  </Link>
                );
              })}
              <Link
                key={"MyTeam"}
                to={`/page/MyTeam`}
                style={{ textDecoration: "none" }}
              >
                {focus === "myteam" ? (
                  <LeftSideSelectLink key={"MyTeam"}>MyTeam</LeftSideSelectLink>
                ) : (
                  <LeftSideLink key={"MyTeam"}>MyTeam</LeftSideLink>
                )}
              </Link>

              <RightSide>
                <Link to={"/SignUp"} style={{ textDecoration: "none" }}>
                  <LeftSideLink>Sign Up</LeftSideLink>
                </Link>
                <Link to={"/login"} style={{ textDecoration: "none" }}>
                  <LeftSideLink>Sign IN</LeftSideLink>
                </Link>
              </RightSide>
            </LeftSide>
          </NavWrapper>
        </NavWrapper>
      </Flex>
    </>
  );
}

export default Nav;
const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;
const HeaderHover = styled.div`
  font-size: 40pt;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: "lckEN";
`;
const HeaderImage = styled.div`
  display: flex;
  position: sticky;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 10px;
  border-top-right-radius: 75%;
  border-bottom-right-radius: 85%;
  min-height: 155px;
`;
const NavContainer = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavWrapper = styled.div`
  width: 100%;
  height: 65px;
  position: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #4f3a6c;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;
const LeftSideSelectLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 10px;
  font-size: 0.9375rem;
  cursor: pointer;
  text-decoration: none;
  color: white;
  height: 65;
  font-family: "lckEN";
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 15px;
`;
const LeftSideLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 10px;
  font-size: 0.9375rem;
  cursor: pointer;
  text-decoration: none;
  color: white;
  height: 52px;
  font-family: "lckEN";
  &:hover {
    color: white;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 15px;
  }
`;
const Navbottom = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  flex-direction: row;
  text-decoration: none;
  color: #a6a6b9;
  height: 65px;
  font-family: "lckEN";
`;
const NavbottomContent = styled.div`
  padding: 8px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #4f3a6c;
    text-decoration: underline;
    text-decoration-thickness: 5px;
    text-underline-offset: 13px;
  }
`;
const NavbottomFocusContent = styled.div`
  padding: 8px;
  display: flex;
  font-size: 0.9375rem;
  align-items: center;
  cursor: pointer;
  color: #4f3a6c;
  text-decoration: underline;
  text-decoration-thickness: 5px;
  text-underline-offset: 13px;
`;
const NavbottomNull = styled.div`
  width: 165px;
  display: flex;
  height: 55px;
`;
