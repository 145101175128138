import React, { useState } from "react";
import styled from "styled-components";
import TeamList from "../atom/TeamList"; // 팀 데이터 임포트

const Community = () => {
  const [activeTab, setActiveTab] = useState("myTeam"); // 기본 활성화된 탭 상태를 myTeam으로 설정
  const [selectedTeam, setSelectedTeam] = useState(null); // 선택된 팀 상태

  // 게시글 더미 데이터
  const posts = {
    myTeam: [
      {
        id: 1,
        title: "MyTeam 게시판 첫 번째 글",
        author: "t1",
        date: "22:39",
        views: 240,
      },
    ],
    others: [
      {
        id: 1,
        team: "T1",
        title: "T1 관련 게시글입니다.",
        author: "페이커",
        date: "22:38",
        views: 196,
      },
      {
        id: 2,
        team: "GenG",
        title: "Gen.G 팀 소식",
        author: "쵸오오비",
        date: "22:37",
        views: 104,
      },
    ],
  };

  // 방어 코드: 잘못된 activeTab이 설정된 경우 기본값으로 복구
  const validTabs = ["myTeam", "others"];
  if (!validTabs.includes(activeTab)) setActiveTab("myTeam");

  // 현재 탭에 따른 게시글 목록 가져오기
  const currentPosts =
    activeTab === "others"
      ? selectedTeam
        ? posts.others.filter((post) => post.team === selectedTeam)
        : posts.others
      : posts[activeTab] || []; // 방어 코드: posts[activeTab]가 undefined일 경우 빈 배열로 설정

  // 팀 로고 클릭 핸들러
  const handleTeamClick = (team) => {
    // 방어 코드: 잘못된 팀 선택 방지
    const validTeams = TeamList.map((team) => team.name);
    if (!validTeams.includes(team)) {
      setSelectedTeam(null);
      return;
    }
    setSelectedTeam(team); // 선택된 팀 상태 업데이트
  };

  return (
    <Wrapper>
      <Container>
        <Header>
          <Tab
            isActive={activeTab === "myTeam"}
            onClick={() => setActiveTab("myTeam")}
          >
            MyTeam
          </Tab>
          <Tab
            isActive={activeTab === "others"}
            onClick={() => {
              setActiveTab("others");
              setSelectedTeam(null); // Others 탭으로 전환 시 선택된 팀 초기화
            }}
          >
            Others
          </Tab>
        </Header>

        {activeTab === "others" && (
          <TeamSelector>
            {TeamList.map(({ name, path }) => (
              <TeamLogo
                key={name}
                onClick={() => handleTeamClick(name)}
                isSelected={selectedTeam === name}
              >
                <img height={32} src={path} alt={name} />
              </TeamLogo>
            ))}
          </TeamSelector>
        )}

        <Content>
          {currentPosts.length > 0 ? (
            currentPosts.map((post) => (
              <Post key={post.id}>
                <Title>{post.title}</Title>
                <Info>
                  <span>{post.author}</span> | <span>{post.date}</span> |
                  조회수: {post.views}
                </Info>
              </Post>
            ))
          ) : (
            <NoPostsMessage>게시글이 없습니다.</NoPostsMessage> // 게시글이 없을 때의 메시지
          )}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Community;

// Styled-components
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
  font-family: "Dohyeon";
  padding: 20px;
`;

const Container = styled.div`
  width: 80%;
  max-width: 1200px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  background: #4c2e84;
`;

const Tab = styled.button`
  flex: 1;
  padding: 15px;
  font-size: 18px;
  color: ${(props) => (props.isActive ? "#fff" : "#bbb")};
  background: ${(props) => (props.isActive ? "#6a43a3" : "transparent")};
  border: none;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: #6a43a3;
  }
`;

const TeamSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #f0f0f0;
  border-bottom: 1px solid #ddd;
`;

const TeamLogo = styled.div`
  margin: 0 10px;
  padding: 5px;
  border: ${(props) => (props.isSelected ? "2px solid #6a43a3" : "none")};
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.2);
  }
`;

const Content = styled.div`
  padding: 20px;
`;

const Post = styled.div`
  padding: 15px 10px;
  border-bottom: 1px solid #e0e0e0;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

const Info = styled.div`
  font-size: 14px;
  color: #888;
  margin-top: 5px;
`;

const NoPostsMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: #999;
  padding: 20px 0;
`;
