import React, { useEffect, useState } from "react";
import PageLayout from "../../templates/PageLayout";
import LaneList from "../../atom/LaneList";
import { MyTeam } from "../../atom/Atoms";
import styled from "styled-components";
import unknown from "../../assets/images/select-removebg.png";
import selectTop from "../../assets/images/selectTop.png";
import Lane from "../../components/Lane";
import DraftScore from "../../components/DraftScore";
import { instanceAxios } from "../../api/axios";
import { useRecoilState } from "recoil";
import { Link, useLocation } from "react-router-dom";

const Draft = () => {
  const location = useLocation();
  const fantasyFocus = location.pathname.split("/")[3];
  const [laneOpen, setLaneOpen] = useState({
    top: true,
    jug: false,
    mid: false,
    bot: false,
    sup: false,
  });
  const [laneSelect, setLaneSelect] = useState("");
  const [playerList, setPlayerList] = useState([]);
  const [myTeam, setMyTeam] = useRecoilState(MyTeam);

  const onLaneClick = (lane) => {
    const newLaneOpen = {
      top: lane === "TOP",
      jug: lane === "JUG",
      mid: lane === "MID",
      bot: lane === "ADC",
      sup: lane === "SUP",
    };
    setLaneOpen(newLaneOpen);
    setLaneSelect(lane);
    console.log("onLaneClick", newLaneOpen);
  };

  useEffect(() => {
    const getPlayer = async () => {
      try {
        const response = await instanceAxios.get("/player");
        if (response.status === 200) {
          setPlayerList(
            response.data.sort((a, b) => b.points - a.points) // 내림차순
          );
        }
      } catch (err) {
        console.error(err);
      }
    };

    getPlayer();
    resetTeam();
    console.log(myTeam, "팀");
  }, []);

  const resetTeam = () => {
    setMyTeam({
      top: { points: 0, name: "", team: "" },
      jug: { points: 0, name: "", team: "" },
      mid: { points: 0, name: "", team: "" },
      adc: { points: 0, name: "", team: "" },
      sup: { points: 0, name: "", team: "" },
    });
  };

  return (
    <>
      <PageLayout subtitle="draft" />
      <DraftWapper>
        <PageWapper>
          <LaneListWrapper>
            <DraftScore />
          </LaneListWrapper>
          <Wapper>
            <WapperLink>
              <List>
                {LaneList.map(({ lane, path }) => (
                  <Linker onClick={() => onLaneClick(lane)} key={lane}>
                    <img height={44} src={path} alt={lane} />
                  </Linker>
                ))}
                <Linker onClick={resetTeam}></Linker>
              </List>
            </WapperLink>
            <WapperLane className="Player">
              {Object.values(myTeam).map((player, index) =>
                player.name ? (
                  <PlayerImage
                    key={index}
                    src={`https://assets.lckfantasy.com/PLAYERS/${player.name}.png`}
                    alt={player.name}
                    index={index}
                  />
                ) : (
                  <UnKnownImage
                    key={index}
                    src={selectTop}
                    alt="Unknown"
                    index={index}
                  />
                )
              )}
            </WapperLane>
            <WapperName>
              {Object.values(myTeam).map((player, index) => (
                <UnKnownName
                  key={index}
                  index={index}
                  style={{ fontFamily: "Dohyeon" }}
                >
                  {player?.name || LaneList[index].lane}
                </UnKnownName>
              ))}
            </WapperName>
          </Wapper>
          <LaneListWrapper>
            <Lane
              lane={laneSelect}
              item={playerList.filter((players) => players.Pos === laneSelect)}
            />
          </LaneListWrapper>
        </PageWapper>
      </DraftWapper>
    </>
  );
};

export default Draft;

const DraftWapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom right, #e8e6f5, #cbd0ee);
`;

const Wapper = styled.div`
  width: 600px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
`;

const PageWapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
`;

const WapperLink = styled.div`
  width: 100%;
  float: bottom;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Linker = styled.div`
  margin-left: 35px;
  padding-top: 15px;
  &:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
`;

const LaneListWrapper = styled.div`
  display: flex;
  width: 300px;
`;

const WapperLane = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  height: 40%;
`;

const PlayerImage = styled.img`
  position: absolute;
  left: ${(props) => `${props.index * 15}%`};
  width: 40%;
  z-index: 10;
`;

const UnKnownImage = styled.img`
  position: absolute;
  left: ${(props) => `${props.index * 15}%`};
  width: 40%;
`;

const UnKnownName = styled.div`
  width: 14%;
  align-items: center;
  font-size: 17px;
  background-color: white;
  text-align: center;
  padding: 5px;
  border-radius: 8px;
  word-wrap: break-word;
`;

const WapperName = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 100%;
`;
