import React from "react";
import styled from "styled-components";
// postShortcode 게시물의 ID
const InstagramThumbnail = ({ postShortcode }) => {
  const thumbnailUrl = `https://instagram.com/p/${postShortcode}/media/?size=l`; // 썸네일 URL
  const postUrl = `https://www.instagram.com/p/${postShortcode}/`; // 게시물 링크
  return (
    <Wrapper>
      <StyledLink href={postUrl} target="_blank" rel="noopener noreferrer">
        <Thumbnail src={thumbnailUrl} alt="Instagram Thumbnail" />
      </StyledLink>
    </Wrapper>
  );
};

export default InstagramThumbnail;

// Styled Components
const Wrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Title = styled.h1`
  color: #4a3d8f;
  font-size: 24px;
  margin-bottom: 20px;
`;

const StyledLink = styled.a`
  display: inline-block;
  text-decoration: none;
`;

const Thumbnail = styled.img`
  max-width: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
`;
