import React from "react";
import styled from "styled-components";

const Card = styled.div`
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  background-color: #e0e0e0;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const CardTitle = styled.h2`
  margin: 0;
  font-size: 1.2rem;
`;

const CardContent = styled.div`
  padding: 15px;
  flex-grow: 1;
`;

const DescriptionBox = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Description</CardTitle>
      </CardHeader>
      <CardContent>
        <p>
          This section can contain textual explanations or introductions about
          the player, game, or any other relevant information.
        </p>
        {/* Add more content for description */}
      </CardContent>
    </Card>
  );
};

export default DescriptionBox;
